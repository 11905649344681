import AppLayout from "@components/Layout/AppLayout";
import Head from "@components/Commons/Head";
import { MarketingOverviewDashboard } from "@components/Dashboards/MarketingOverview/MarketingOverviewDashboard";
import { DEPLOY_ENVIRONMENT } from "constants/constants";

function MarketingIntelligencePage() {
  return (
    <div className="min-h-screen scrollbar">
      <Head title="Marketing Intelligence Dashboard – Tracify AI" />
      <MarketingOverviewDashboard />
    </div>
  );
}

MarketingIntelligencePage.Layout = AppLayout;

export async function getStaticProps() {
  return {
    props: {
      approvals:
        DEPLOY_ENVIRONMENT === "beta"
          ? ["access_rts_dashboard", "access_beta_environment"]
          : ["access_rts_dashboard"],
    },
  };
}

export default MarketingIntelligencePage;
